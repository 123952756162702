.product-search-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
}

h2 {
  text-align: center;
  font-size: 24px;
  color: #333;
}

.search-instructions {
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: -8px;
  margin-bottom: 16px;
}

/* Sticky Container */
.sticky-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Search Bar */
.search-bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar input {
  width: 60%;
  padding: 10px 40px 10px 10px;
  font-size: 16px;
  border: 2px solid #8B0000; /* Maroon border */
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.search-bar input:focus {
  border-color: #8B0000;
  border-width: 3px;
  box-shadow: 0 0 8px rgba(139, 0, 0, 0.4);
  cursor: crosshair;
}

.clear-button {
  position: absolute;
  right: 20%;
  background: transparent;
  border: none;
  font-size: 16px;
  color: #888;
  cursor: pointer;
  outline: none;
}

.clear-button:hover {
  color: #333;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  transition: transform 0.3s;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.product-card h3 {
  font-size: 18px;
  margin: 8px 0;
}

.product-image {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
}

.placeholder-image {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  font-size: 14px;
  color: #888;
}

a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

a:hover {
  text-decoration: underline;
}

.no-results {
  text-align: center;
  color: #888;
  font-size: 16px;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #8B0000;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #5a0000;
}